import React from 'react';

const Foundation = () => {
  return (
    <div>
      <h3 className="text-2xl font-bold mb-4">strategy & OKRs</h3>
      <p>
      Let's get the foundation right. Let's start by figuring out the Why, Who, What, and How for your project.


            “Why?” should we do this?
            The question “Why?” will identify the goals of the project. You will be able to identify the purpose and the key performance indicators, core metrics and OKRs  of your project.
            Once we have established your “Why”, you need to consider for “Who?” you are creating this.


            “Who?” are the ones that create the desired effect of your project and that we should build the solution for?Who are your users, and which users are important for the success of your project.


            “What” are the needs of your user? What is the minimum required functionality for your project to ensure that you reach your goals. Following the “What?” question, you need to ask yourself “How?”.
            “How” should the product and/or service be designed to fulfill the user goals?
            The “How?” question will enable us to build the user experience and design that will ultimately be the key driver behind your growth and success. 
            
            I am sure you see how interlinked each question is and by going through a process together where we discuss each one of these questions we will build a strong foundation for growth for your project.

      </p>
    </div>
  );
};

export default Foundation;
