import React from 'react';

const Exploration = () => {
  return (
    <div>
      <h3 className="text-2xl font-bold mb-4">user experience design</h3>
      <p>
        Building digital products, driving digital transformation, and achieving growth from digital products and services is complex and difficult.
        I believe that the foundation of a successful digital project is the User Experience (UX). 
        The User Experience is what has been the key driver behind the success of some of the biggest companies in the world.
        Apple with its unique phone and tablet experience, Google with its simple and easy-to-use search interface, Facebook with its “endless scrolling” functionality, and Instagram with its easy-to-share and view content platform just to name a few examples.
        McKinsey (2020) has in a report released the main factors behind the success of some the fastest growing digital products and services. Excellent user designs and strong end user focus throughout the development process was pinpointed as one of the main factors.
        It’s the User Experience that drives the transformation that you are looking for in your project, and it's the “secret sauce” for the projects and solutions that ultimately makes a difference and has an impact.
        I have built up competence from more than 10 years of combined experience within the digital arena, and I can help you find your “secret sauce”. 
        The UX/UI process process involves creating user journeys and mockups that we test and iterate on ensuring we find the optimal solution for your users. 

      </p>
    </div>
  );
};

export default Exploration;
