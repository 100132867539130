import React from 'react';

const Launch = () => {
  return (
    <div>
      <h3 className="text-2xl font-bold mb-4">monitoring performance</h3>
      <p>
      Setting up analytic dashboards that not only monitor the KPIs / OKRs of the project but also can support A/B testing processes, is critical for the success of the product and I can support that full process. I can also support the OKR creation process ensuring that goals and targets for the product before launch are clear and can be easily measured.
        The roadmap will function as a central communication device as a part of the ongoing development of the product and the analytics dashboards will help enable us to monitor our metrics and keep the pulse of the product in control.
        On-going experimentation and testing are the key to a successful product and setting up an infrastructure that can support that we will take us close to user adoption and success. 

      </p>
    </div>
  );
};

export default Launch;
