import React from 'react';

const DevelopmentTesting = () => {
  return (
    <div>
      <h3 className="text-2xl font-bold mb-4">continuous learning</h3>
      <p>
      If you start your digital project without testing the idea with your users you are making a huge gamble of your money and resources. The risk is that you start to build something that does not have the right foundation.  You might realize when you have already worked your way through half your budget in development costs, that the original designs and ideas were not what your end users need. To start over and rebuild is expensive, not to mention the loss of time and frustration that you will experience.
        Let's run multiple different variants and learn from user interactions what gives the best results. Let's use the data we collect from this process to feed our roadmap so that we can iterate on the product efficiently and learn from the users and how they interact with the product while also using this as a method to set priorities and find the direction of the product.
        I embrace an agile development methodology that ensures flexibility and adaptability throughout the project lifecycle. This iterative approach allows us to respond promptly to evolving requirements incorporate feedback seamlessly, and deliver high-quality solutions efficiently.
        I have experience working in multiple different project environments, tools, and infrastructures, e.g. Jira, Monday.com, Miro, and Salesforce to just mention a few. I will structure the entire product lifecycle through various tools that will give you the flexibility and overview that you need to make the right decisions. 
        I can set up and manage the entire infrastructure needed for the product (effectively functioning as a CTO) and ensure that the product can scale as you reach product success. If needed, I can also support you as a coder in your project as I have experience in building full-stack applications by myself. 

      </p>
    </div>
  );
};

export default DevelopmentTesting;
